import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    redirect: "/login",
    meta: {
      title: "登录",
    },
  },
  {
    path: "/login",
    name: "login",
    meta: {
      title: "登录",
    },
    component: (resolve) => require(["../views/Login/login.vue"], resolve),
  },
  {
    path: "/home",
    name: "home",
    meta: {
      title: "首页",
    },
    component: (resolve) => require(["../views/Home/home.vue"], resolve),
  },
  {
    path: "/search",
    name: "search",
    meta: {
      title: "搜索",
    },
    component: (resolve) => require(["../views/Search/search.vue"], resolve),
  },
];

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
