<template>
  <div id="app">
    <router-view v-if="isRouterAlive" />
  </div>
</template>
<script>
export default {
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
  },
};
</script>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: border-box;
}

body,
html {
  width: 100%;
  height: 100%;
  background-color: #F8FAFC !important;
}

#app {
  font-size: $font-size-m;
  width: 100%;
  height: auto;

  .page-content {
    width: 100%;
    min-height: calc(100vh - 100px);
    padding: 1em;
  }
}
</style>
