import axios from "axios";
import router from "../router";
import { Toast } from "vant";

const request = axios.create({
  baseURL: "",
  timeout: 50000,
  withCredentials: false,
});

request.interceptors.request.use(
  function (config) {
    let token = sessionStorage.getItem("stock_account_token");

    if (token !== null) {
      config.headers['Authorization'] = token;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  function (response) {
    if (response.status != 200) {
      // exception 
    }

    return response.data;
  },
  function (error) {

    if (error.response.status == 401) {
      Toast.fail("登錄已過期，請重新登錄");

      sessionStorage.removeItem("account_info");
      sessionStorage.removeItem("stock_account_alias_id");
      sessionStorage.removeItem("stock_account_token");

      setTimeout(() => {
        router.replace({ path: "/login" });
      }, 1000);
    } else if (error.response.status == 400) {
      // response.data.message
      Toast.fail(error.response.data.error.message ? error.response.data.error.message : '發生錯誤');
    }

    return Promise.reject(error);
  }
);

export default {
  ...request,
  get: (url, params) => {
    let queryString = '';

    if (params) {
      queryString = new URLSearchParams(params).toString();
    }
    
    return request.get(`${url}?${queryString}`);
  },
    
};
