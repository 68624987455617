import request from "@/utils/axios";
import QS from "qs";

const api = {
  // 登入
  loginToStock: (params) => request.post(process.env.VUE_APP_API_BASE_URL + "/auth", QS.stringify(params)),

  // 登出
  logout: () => request.delete(process.env.VUE_APP_API_BASE_URL + "/auth"),

  // 取得會員資訊
  getAccountDetail: (params) => request.get(process.env.VUE_APP_API_BASE_URL + `/account/${params.alias_id}`),

  // 取得會員資訊
  getAccountStockStatus: (params) => request.get(process.env.VUE_APP_API_BASE_URL + `/stocks/account-total-amplitude/${params.alias_id}`),

  // 取得股票清單
  getStockList: () => request.get(process.env.VUE_APP_API_BASE_URL + `/stocks`),

  // 取得股票資料
  getStockDetail: (params) => request.get(process.env.VUE_APP_API_BASE_URL + `/stocks/${params.stock_id}`, params),

  // 取得股票財報
  getStockFinacialReport: (params) => request.get(process.env.VUE_APP_API_BASE_URL + `/stocks/${params.stock_id}/finacial-report`, params),

  // 更新會員密碼
  updateAccountPassword: (params) => request.put(process.env.VUE_APP_API_BASE_URL + `/account/${params.alias_id}/password`, QS.stringify(params)),

  // 更新會員權限
  updateAccountPermission: (params) => request.put(process.env.VUE_APP_API_BASE_URL + `/account/${params.alias_id}/permission`, QS.stringify(params)),

  // 更新會員持股
  updateAccountScore: (params) => request.put(process.env.VUE_APP_API_BASE_URL + `/account/${params.alias_id}/score`, QS.stringify(params)),
};

export default api;

