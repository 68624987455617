import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import api from "./api/index";
import store from "./store";
import './assets/scss/bs.scss';
import './assets/scss/theme.scss';
import "./assets/css/reset.css";
import Vant from "vant";
import "vant/lib/index.css";
import "lib-flexible";
import HighchartsVue from 'highcharts-vue';
import { ActionSheet, Collapse, CollapseItem, Dialog, Popover, Popup, Search, Switch, Toast } from "vant";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);
Vue.use(Vant);
Vue.use(HighchartsVue);
Vue.use(ActionSheet);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Dialog);
Vue.use(Popover);
Vue.use(Popup);
Vue.use(Search);
Vue.use(Switch);
Vue.use(Toast);
Vue.config.productionTip = false;
Vue.prototype.$api = api;
let aaa = location.href.split(/language=/)[1];
aaa
  ? sessionStorage.setItem("locale", aaa)
  : sessionStorage.setItem("locale", "zh");
const i18n = new VueI18n({
  locale: sessionStorage.getItem("locale"), // 语言标识
  messages: {
    zh: require("./assets/lang/zh"),
    en: require("./assets/lang/en"),
  },
});

//恢复滚动高度
router.afterEach(() => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
});
new Vue({
  router,
  i18n,
  store,
  render: (h) => h(App),
}).$mount("#app");
